"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockTypes = void 0;
var BlockTypes;
(function (BlockTypes) {
    BlockTypes["lecture"] = "lecture";
    BlockTypes["workshop"] = "workshop";
    BlockTypes["lab"] = "lab";
    BlockTypes["review"] = "review";
    BlockTypes["quiz"] = "quiz";
    BlockTypes["exam"] = "exam";
})(BlockTypes || (exports.BlockTypes = BlockTypes = {}));
